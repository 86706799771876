import { createContext, useCallback, useEffect } from 'react';
import jwt_decode, { JwtPayload } from 'jwt-decode';

import apiOnboarding from '@src/services/api-onboarding';

type AuthOnboardingContextData = {
  verifyLogin(): Promise<void>;
};

export const AuthOnboardingContext = createContext(
  {} as AuthOnboardingContextData
);

export const OnboardingProvider: React.FC = ({ children }) => {
  useEffect(() => {
    const storagedToken = localStorage.getItem('@Onboarding:token');
    if (storagedToken) {
      apiOnboarding.defaults.headers.Authorization = `Bearer ${storagedToken}`;
    }
  }, []);

  const login = useCallback(async () => {
    const response = await apiOnboarding.post<{
      token_type: string;
      expires_in: number;
      access_token: string;
    }>('oauth/token', {
      grant_type: 'client_credentials',
      client_id: process.env.REACT_APP_API_ONBOARDING_CLIENT_ID,
      client_secret: process.env.REACT_APP_API_ONBOARDING_CLIENT_SECRET,
    });
    apiOnboarding.defaults.headers.Authorization = `Bearer ${response.data.access_token}`;
    localStorage.setItem('@Onboarding:token', response.data.access_token);
  }, []);

  const verifyLogin = useCallback(async () => {
    const token = localStorage.getItem('@Onboarding:token');
    if (token) {
      const decodedToken = jwt_decode<JwtPayload>(token);
      const currentDate = new Date();
      const currentTimeInSeconds = Math.floor(currentDate.getTime() / 1000);

      const expirationTime = decodedToken.exp ?? 0;

      const remainingTime = expirationTime - currentTimeInSeconds;

      if (remainingTime < 1800) {
        await login();
      }
    } else {
      await login();
    }
  }, [login]);

  return (
    <AuthOnboardingContext.Provider value={{ verifyLogin }}>
      {children}
    </AuthOnboardingContext.Provider>
  );
};
